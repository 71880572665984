<template>
  <div class="user-activation">
    <transition name="fade">
      <section
        v-if="activating"
        class="user-activation__activating section"
      >
        <div class="section-body">
          <div class="container">
            <div class="has-text-centered is-size-5">
              <i class="hf hf-spinner hf-pulse" /> {{ $t('pages.user_activation.activating') }}
            </div>
          </div>
        </div>
      </section>
      <section
        v-if="expired"
        class="user-activation__expired section"
      >
        <div class="section-body">
          <div class="container">
            <div class="columns is-centered">
              <div class="column is-10 is-5-desktop">
                <transition name="fade">
                  <div
                    v-show="!activationSent"
                    class="form"
                  >
                    <h5 class="title is-5">
                      {{ $t('pages.user_activation.activation_code_expired') }}
                    </h5>
                    <form @submit.prevent="sendNewActivation">
                      <form-field
                        :validator="$v.form.email"
                        :label="$t('forms.email')"
                        hide-label
                      >
                        <template slot="control">
                          <div class="field is-grouped">
                            <div class="control is-expanded">
                              <input
                                v-model="form.email"
                                :placeholder="$t('forms.email')"
                                class="input"
                                type="email"
                                name="email"
                                @input="$v.form.email.$touch()"
                              >
                            </div>
                            <div class="control">
                              <button
                                :class="{ 'is-loading': isLoading }"
                                class="button is-primary"
                              >
                                {{ $t('forms.send') }}
                              </button>
                            </div>
                          </div>
                        </template>
                      </form-field>
                    </form>
                  </div>
                  <div class="notification is-success">
                    {{ $t('success.successfully_activated_user') }}
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </section>
    </transition>
  </div>
</template>
<script>
import { required, email } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'

export default {
  props: {
    activationToken: { type: String, default: '' }
  },
  validations: {
    form: {
      email: { required, email }
    }
  },
  data () {
    return {
      activating: true,
      expired: false,
      activationSent: false,
      isLoading: false,
      form: {
        email: ''
      }
    }
  },
  mounted () {
    this.tryToActivateUser()
  },
  methods: {
    ...mapActions(['logoutGlobally', 'loginProcess']),
    tryToActivateUser () {
      this.$api.post('activation/complete', {
        token: this.activationToken
      })
        .then(async ({ data }) => {
          this.activating = false
          this.$notify.success(this.$t('success.successfully_activated_user'))
          await this.logoutGlobally()
          await this.loginProcess(data.data)
          this.$router.replace({ name: 'dashboard' })
        })
        .catch(error => {
          this.activating = false
          const statusCode = this.$safeGet(error, 'response.status', 0)
          if (statusCode === 404) {
            this.$router.push(this.$i18nRoute({ name: 'home' }))
            this.$displayRequestError(error, this.$t('errors.user_could_not_be_activated'))
          } else if (statusCode === 410) {
            this.expired = true
          } else {
            this.$notify.error(this.$t('errors.user_could_not_be_activated'))
          }
        })
    },
    sendNewActivation () {
      this.$v.form.$touch()
      if (this.$v.form.$error) return
      this.isLoading = true
      this.$api.post('activation/resend', this.form)
        .then(response => {
          this.isLoading = false
          this.activationSent = true
          this.$notify.success(this.$t('success.successfully_sent_new_email_with_token'))
        })
        .catch(error => {
          this.isLoading = false
          this.$displayRequestError(error)
        })
    }
  }
}
</script>
